@import url('./fonts/HelveticaNeueCyr/stylesheet.css');
@import 'slick-carousel/slick/slick.css';
@import 'slick-carousel/slick/slick-theme.css';
:root {
  --main: #3198c9;
  --deep-gray: #363636;
  --grey: #c4c4c4;
  --deep-ocher: #6b989b;
  --ocher: #72b9db;
  --light-ocher: #c0eaef;
}
*,
*::before,
*::after,
*::placeholder {
  margin: 0;
  padding: 0;
  font-family: 'HelveticaNeueCyr', sans-serif;
  box-sizing: border-box;
}
*:focus {
  outline: 0;
}
html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overflow-x: hidden;
}
.wrapper {
  width: 100%;
  max-width: 1300px;
  padding: 0 30px;
  margin: 0 auto;
}
.wrapper.small {
  max-width: 1028px;
}
.big_title {
  font-weight: 900;
  font-size: 70px;
  line-height: 68px;
  letter-spacing: 0.0005em;
  text-transform: uppercase;
  color: var(--deep-gray);
}
.leading {
  font-weight: 300;
  font-size: 20px;
  line-height: 130%;
  letter-spacing: 0.03em;
}
.title {
  font-weight: 900;
  font-size: 60px;
  line-height: 58px;
  letter-spacing: 0.0005em;
  color: var(--deep-gray);
  text-transform: uppercase;
}
.title.bg {
  -webkit-text-stroke-width: 3px;
  -webkit-text-stroke-color: var(--deep-ocher);
  background: transparent no-repeat;
  -webkit-background-clip: text;
  background-clip: text;
  text-transform: uppercase;
  -webkit-text-stroke-width: 2px;
  -webkit-text-fill-color: transparent;
}
.slick-slide {
  transform: translateX(-35%) !important;
}
.btn {
  background-color: var(--main);
  border-radius: 4px;
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.0005em;
  outline: 0;
  border: 0;
  height: 60px;
  width: 100%;
  color: #fff;
  max-width: 280px;
  margin: 0 auto;
  cursor: pointer;
}
.btn.link {
  display: flex;
}
.btn.link a {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn.mobile {
  height: 50px;
  font-size: 16px;
  margin: 0;
}
.slick-track {
  padding: 24px 0;
}
@media (max-width: 1000px) {
  .big_title {
    font-size: 48px;
    line-height: 64px;
  }
}
@media (max-width: 990px) {
  .big_title {
    font-size: 36px;
    line-height: 48px;
  }
  .leading {
    font-size: 18px;
    line-height: 1.3;
  }
  .title {
    font-size: 48px;
    line-height: 56px;
  }
}
@media (max-width: 576px) {
  .big_title {
    font-size: 38px;
    line-height: 37px;
  }
  .btn {
    height: 50px;
    font-size: 16px;
    margin: 0;
  }
  .title {
    font-size: 36px;
    line-height: 48px;
  }
  .dotted {
    transform: scale(0.7);
  }
  .slick-slide {
    transform: translateX(50%) !important;
  }
}
@media (max-width: 360px) {
  .wrapper {
    padding: 0 16px;
  }
}
