.container {
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    object-fit: cover;
    backdrop-filter: blur(5px);
  }

  .inner {
    max-width: 300px;
    position: relative;
    padding: 50px 0;
    z-index: 2;
    .logo {
      width: 140px;
      img {
        width: 100%;
        height: auto;
      }
    }
    .contact {
      display: flex;
      align-items: center;
      margin-top: 16px;
      span {
        margin-left: 10px;
      }
    }
    @media (max-width: 576px) {
      p {
        font-size: 14px;
        max-width: 180px;
      }
      .contact {
        display: none;
      }
    }
  }
}
