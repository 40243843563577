.container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  background: #fff;
  @media (max-width: 576px) {
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 15px -5px,
    //   rgba(0, 0, 0, 0.04) 0px 1px 10px 0px;
    box-shadow: none;
  }
  .inner {
    display: flex;
    height: 90px;
    align-items: center;
    justify-content: space-between;
    position: relative;
    @media (max-width: 576px) {
      height: 60px;
    }
    .logo {
      max-width: 130px;
      min-width: 80px;
      &,
      a {
        display: flex;
        align-items: center;
      }

      @media (max-width: 576px) {
        width: 80px;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .hamburger {
      background: transparent;
      border: 0;
      outline: 0;
      width: 32px;
      height: 32px;
      display: none;
      z-index: 10;
      @media (max-width: 576px) {
        display: block;
      }
    }

    .nav_list {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      @media (max-width: 576px) {
        display: none;
      }
      li {
        margin-left: 40px;
        font-size: 18px;
        cursor: pointer;
        @media (max-width: 767px) {
          margin-left: 30px;
          font-size: 16px;
        }
        a {
          color: var(--deep-gray);
          text-decoration: none;
          border-bottom: 2px solid transparent;
          padding: 5px;
          transition: 0.5s;
          &:hover {
            border-bottom: 2px solid var(--main);
          }
        }
      }
    }
    .dropdown {
      position: absolute;
      top: 60px;
      left: -16px;
      width: calc(100% + 32px);
      height: 250px;
      padding: 24px;
      background: #fff;
      z-index: -1;
      .list {
        list-style-type: none;
        margin: 0;
        padding: 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        li {
          padding: 5px;
          margin-left: 0;
          font-size: 18px;
          cursor: pointer;
          a {
            color: var(--deep-gray);
            text-decoration: none;
          }
        }
      }
    }
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
