.container {
  padding: 105px 0;
  position: relative;
  /* & > svg {
    position: absolute;
    top: -20%;
    left: 0;
    z-index: -1;
    @media (max-width: 576px) {
      top: -10%;
      right: -40%;
      left: auto;
    }
  } */
  .inner {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-gap: 24px;

    .left {
      overflow: hidden;
      .img_cont {
        min-width: 150px;
        width: 100%;
        max-height: 400px;
        position: relative;
        img {
          width: 100%;
          height: auto;
        }
        .top_element {
          background: #72b9db;
          height: 72px;
          position: absolute;
          top: -24px;
          left: 0;
          width: 110%;
          z-index: -1;
          display: none;
        }
        @media (max-width: 990px) {
          padding-top: 20px;
          max-height: 315px;
          .top_element {
            display: initial;
          }
        }
      }
      p {
        display: none;
        writing-mode: vertical-rl;
        transform: rotate(180deg);
        text-align: left;
        @media (max-width: 990px) {
          display: block;
        }
      }
    }
    .right {
      max-width: 650px;
      & > p:nth-child(1) {
        margin-bottom: 30px;
      }
      .features {
        display: flex;
        align-items: center;
        margin: 10px 0 24px;
        @media (max-width: 950px) {
          flex-wrap: wrap;
        }
        @media (max-width: 576px) {
          display: none;
        }
        span {
          font-weight: 900;
          font-size: 30px;
          line-height: 32px;
          letter-spacing: 0.0005em;
          text-transform: uppercase;
          color: #6b989b;
          margin-left: 5px;
          margin-right: 5px;
        }
        /* svg {
          margin: 0 10px;
        } */
      }
    }
    @media (max-width: 990px) {
      grid-template-columns: 1fr;
      .left {
        overflow: hidden;
        max-height: 400px;
        display: flex;
      }
      .right {
        p:nth-child(1) {
          display: none;
        }
      }
    }
  }
}
