*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.partners{
    margin-top: 120px;
    margin-bottom: 60px;

}

@media(max-width: 990px){
    .photo-resp{
        width: 100px;
    }
    .partners-img{
      width: 60px;
      margin-left: 1.5rem;
    }
}
@media (max-width: 990px) {
    .partners{
        margin: 30px 0 20px;
    }
}
