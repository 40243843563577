.container {
  height: calc(100vh - 80px);
  min-height: 500px;
  max-height: 700px;
  padding: 120px 0 24px;
  position: relative;
  @media (max-width: 990px) {
    height: auto;
    padding-top: 60px;
    max-height: unset;
  }
  & > div {
    height: 100%;
  }
  .inner {
    height: 100%;
    display: grid;
    grid-template-columns: 5fr 4fr;
    grid-gap: 32px;
    @media (max-width: 990px) {
      grid-template-columns: 1fr;
    }
    .left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      svg {
        position: fixed;
        top: 30%;
        transform: translateY(-50%);
        z-index: -1;
      }
      @media (max-width: 990px) {
        order: 2;
        padding-top: 24px;
        /* svg {
          top: auto;
          z-index: -1;
          left: -50%;
          bottom: -0;
        } */
        div:nth-child(3) {
          margin: 16px 0;
          max-width: 250px;
        }
      }
    }
    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding-right: 100px;
      @media (max-width: 990px) {
        padding-right: 0;
      }
      .img_cont {
        position: relative;
        margin-left: -5vw;
        @media (max-width: 1000px) {
          margin-left: 0;
        }
        @media (max-width: 990px) {
          margin-left: -30px;
        }
        @media (max-width: 360px) {
          margin-left: -16px;
        }
        .img {
          position: relative;
          max-height: 500px;
          max-width: 340px;
          @media (max-width: 990px) {
            max-height: 520px;
            width: 100vw;
            max-width: 100vw;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: rgba(38, 159, 216, 0.21);
          }
          overflow: hidden;
        }
        img {
          width: 100%;
          height: auto;
          transform: scale(1.02);
        }
        .top_element,
        .bottom_element {
          z-index: -1;
          height: 20%;
          width: 100%;
          @media (max-width: 990px) {
            bottom: 0;
            left: 0;
            height: 15px;
          }
        }
        .top_element {
          position: absolute;
          top: -5%;
          left: -20%;
          background: var(--light-ocher);
          @media (max-width: 990px) {
            width: 100vw;
            bottom: -15px;
            left: 0;
            top: auto;
          }
        }
        .bottom_element {
          position: absolute;
          bottom: -5%;
          right: -20%;
          background: #8ecdeb;
          @media (max-width: 990px) {
            width: 60vw;
            bottom: -30px;
            left: 0;
            right: auto;
            top: auto;
          }
        }
      }
    }
  }
}
